<template>
    <div class="wrap-off-withdraw">
        <div class="filter">
            <div class="wrap-input">
                <input type="text" class="form-input input-search" placeholder="Search by client or withdrawal code" v-model="search" @input="filterSearch" />
                <img src="@/assets/icon_cms/search.svg" alt="" class="icon-input" />
            </div>
            <div class="wrap-input">
                <!-- <input type="date" class="form-input" placeholder="Search by client or withdrawal code" /> -->
                <dropdown-directive top="2.5rem" width="100%">
                    <template v-slot:buttonDropdown>
                        <div class="form-input">
                            <div v-if="startDate == '' && endDate == ''" style="color: #e5e4e4">Search by Date</div>
                            <div v-else>{{ startDate }} - {{ endDate }}</div>
                        </div>
                        <img src="@/assets/icon_cms/calendar-alt.svg" alt="" class="icon-input" style="top: 0.65rem" />
                    </template>
                    <template v-slot:menuDropdown>
                        <div class="filter-dropdown">
                            <div class="form-group">
                                <label for="">Start Date</label>
                                <input type="date" class="form-input" v-model="startDate" :max="today" />
                            </div>
                            <div class="form-group">
                                <label for="">End Date</label>
                                <input type="date" class="form-input" v-model="endDate" :min="startDate" :max="today" />
                            </div>
                            <div class="d-flex justify-content-end mt2">
                                <button type="button" class="btn btn-close btn-sm" @click="resetFilterDate">Reset</button>
                                <button type="button" class="btn btn-success btn-sm" @click="filterDate">Apply</button>
                            </div>
                        </div>
                    </template>
                </dropdown-directive>
            </div>
            <div class="wrap-input">
                <dropdown-directive top="2.5rem" width="100%">
                    <template v-slot:buttonDropdown>
                        <div class="form-input">
                            <div v-if="statusValue.length === 0" style="color: #e5e4e4">Search by Status</div>
                            <div v-else class="content-filter-status">
                                {{ resultFilterStatus }}
                            </div>
                            <img src="@/assets/icon_cms/angle-down.svg" alt="" class="icon-input" />
                        </div>
                    </template>
                    <template v-slot:menuDropdown>
                        <ul class="filter-dropdown">
                            <li v-for="(status, i) in dataStatus" :key="i">
                                <input type="checkbox" :value="status.code" v-model="statusValue" @change="filterStatus" />
                                <label for="">{{ status.label }}</label>
                            </li>
                        </ul>
                    </template>
                </dropdown-directive>
            </div>
            <button type="button" class="btn btn-reset btn-sm" v-if="endDate != '' || statusValue.length > 0 || search != ''" @click="resetAllFilter">Reset</button>
        </div>
        <div class="view-perpages">
            <select v-model="perPage" @change="showDataRow">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            <span>&nbsp;entries per page</span>
        </div>
        <table>
            <thead>
                <tr id="top">
                    <th scope="col">Client</th>
                    <th scope="col">Withdrawal Code</th>
                    <th scope="col">Total Denom (gr)</th>
                    <th scope="col">Qty of pcs</th>
                    <th scope="col">Withdrawal Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody v-if="getIsLoading">
                <tr>
                    <td colspan="7" class="text-center">
                        <b-spinner label="Loading..."></b-spinner>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="(data, i) in getWithdrawData" :key="i" v-show="getWithdrawData.length != 0">
                    <td data-label="Client">{{ data.owner }}</td>
                    <td data-label="Withdrawal Code">{{ data.transaction_id }}</td>
                    <td data-label="Total Denom">{{ data.total_weight }}</td>
                    <td data-label="Total Item">{{ data.total_item }}</td>
                    <td data-label="Withdrawal Date" v-if="data.withdrawal_detail">{{ data.withdrawal_detail.pickup_time | formatDateTimeReal }}</td>
                    <td data-label="Status" class="text-center">
                        <span v-show="data.status == '001'" class="status-withdrawal kmi">Waiting for KMI Admin Approval</span>
                        <span v-show="data.status == '002'" class="status-withdrawal kbi">Waiting for KBI Admin Approval</span>
                        <span v-show="data.status == '003'" class="status-withdrawal recipient">Waiting for Upload Recipient Identity</span>
                        <span v-show="data.status == '004'" class="status-withdrawal withdrawn">Waiting for Withdraw</span>
                        <span v-show="data.status == '005'" class="status-withdrawal operator">Waiting for Operator Confirmation</span>
                        <span v-show="data.status == '006'" class="status-withdrawal success">Withdrawal Success</span>
                        <span v-show="data.status == '007'" class="status-withdrawal reject">Withdrawal Rejected</span>
                        <!-- <span v-show="data.status == '008'" class="status-withdrawal handover">Client Withdrawal</span> -->
                    </td>
                    <td data-label="Action">
                        <dropdown-directive top="1rem" right="1.7rem">
                            <template v-slot:buttonDropdown> <img src="@/assets/icon_cms/aksi.svg" alt="" /></template>
                            <template v-slot:menuDropdown>
                                <ul class="menu-dropdown" ref="open-dropdown">
                                    <router-link :to="'/off-exchange/withdrawal/' + data._id">
                                        <li>View Detail</li>
                                    </router-link>
                                    <li :class="{ disableLi: data.status != '002' }" @click="openNotification(data.transaction_id, data._id)">Approve Withdraw</li>
                                </ul>
                            </template>
                        </dropdown-directive>
                    </td>
                </tr>

                <tr v-show="getWithdrawData.length == 0">
                    <td colspan="7">No Data Available in Table</td>
                </tr>
            </tbody>
        </table>
        <div class="content d-flex justify-content-between align-items-center mt-3" v-if="!getIsLoading">
            <div>
                <span>{{ (currentPage - 1) * perPage + 1 }} - {{ currentPage * perPage > getTotalData ? getTotalData : currentPage * perPage }}</span> /
                <span>{{ getTotalData }} Total</span>
            </div>
            <b-pagination
                v-model="currentPage"
                :total-rows="getTotalData"
                :per-page="perPage"
                @change="handlePageChange"
                aria-controls="new-table"
                first-number
                last-number
                v-show="getTotalData > 10"
            ></b-pagination>
        </div>
        <div v-if="isShowNotif">
            <notification title="Approve Withdrawal?" icon="icon_cms/questioning.svg">
                <template v-slot:notifBody>
                    Are you sure you want to approve the withdrawal request with the code <b>{{ withdrawalCode }}</b
                    >?
                </template>
                <template v-slot:notifFoot>
                    <button class="btn btn-success btn-sm btn-notif" @click="approveWithdrawal">Approve</button>
                    <button class="btn btn-close btn-sm btn-notif mt-1" @click="isShowNotif = false">Back</button>
                </template>
            </notification>
        </div>
        <div v-if="isApproved">
            <notification title="Withdrawal Approved" icon="icon_cms/success.svg">
                <template v-slot:notifBody>
                    The withdrawal with code <b>{{ withdrawalCode }}</b> has been approved
                </template>
                <template v-slot:notifFoot>
                    <button
                        class="btn btn-primary btn-sm btn-notif"
                        @click="
                            {
                                (isApproved = false), location.reload();
                            }
                        "
                    >
                        OK
                    </button>
                </template>
            </notification>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import DropdownDirective from "../../../../components/dropdown/DropdownDirective.vue";
import Notification from "../../../../components/Notification.vue";
export default {
    components: { DropdownDirective, Notification },
    data() {
        return {
            denom: "",
            currentPage: 1,
            perPage: 10,
            pieces: "",
            startDate: "",
            endDate: "",
            statusValue: [],
            search: "",
            activity: "withdrawal",
            hallmark: "",
            dateType: "",
            contract_id: "",
            isShowNotif: false,
            isApproved: false,
            withdrawalCode: "",
            withdrawalId: "",
            today: "",
            dataStatus: [
                { code: "001", label: "Waiting for KMI Admin Approval" },
                { code: "002", label: "Waiting for KBI Admin Approval" },
                { code: "003", label: "Waiting for Upload Recipient Identity" },
                { code: "004", label: "Waiting for Withdraw" },
                { code: "005", label: "Waiting for Operator Confirmation" },
                { code: "006", label: "Withdrawal Success" },
                { code: "007", label: "Withdrawal Rejected" },
            ],
        };
    },
    mounted() {
        this.today = new Date().toISOString().split("T")[0];
        this.getData(this.currentPage, this.perPage, this.denom, this.pieces, this.startDate, this.endDate, this.statusValue, this.search);
    },
    methods: {
        getData(currentPage, perPage, denom, pieces, startDate, endDate, status, search) {
            this.$store.dispatch("getWithdrawOff", {
                currentPage: currentPage,
                perPage: perPage,
                denom: denom,
                pieces: pieces,
                startDate: startDate,
                endDate: endDate,
                status: status,
                search: search,
            });
        },
        handlePageChange(value) {
            this.currentPage = value;
            this.getData(this.currentPage, this.perPage, this.denom, this.pieces, this.startDate, this.endDate, this.statusValue, this.search);
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        filterSearch(event) {
            this.currentPage = 1;
            this.search = event.target.value;
            this.getData(this.currentPage, this.perPage, this.denom, this.pieces, this.startDate, this.endDate, this.statusValue, this.search);
        },
        showDataRow() {
            this.getData(this.currentPage, this.perPage, this.denom, this.pieces, this.startDate, this.endDate, this.statusValue, this.search);
        },
        filterStatus() {
            this.currentPage = 1;
            this.getData(this.currentPage, this.perPage, this.denom, this.pieces, this.startDate, this.endDate, this.statusValue, this.search);
        },
        filterDate() {
            this.currentPage = 1;
            this.getData(this.currentPage, this.perPage, this.denom, this.pieces, this.startDate, this.endDate, this.statusValue, this.search);
        },
        resetFilterDate() {
            this.startDate = "";
            this.endDate = "";
            this.currentPage = 1;
            this.getData(this.currentPage, this.perPage, this.denom, this.pieces, this.startDate, this.endDate, this.statusValue, this.search);
        },
        resetAllFilter() {
            this.startDate = "";
            this.endDate = "";
            this.search = "";
            this.statusValue = [];
            this.currentPage = 1;
            this.getData(this.currentPage, this.perPage, this.denom, this.pieces, this.startDate, this.endDate, this.statusValue, this.search);
        },
        openNotification(code, id) {
            this.withdrawalId = id;
            this.withdrawalCode = code;
            this.isShowNotif = true;
        },
        approveWithdrawal() {
            this.$store.dispatch("getLocation").then((resGeo) => {
                let info;
                if (resGeo.cityData[0]) {
                    info = JSON.stringify(resGeo.cityData[0]);
                } else {
                    let data = resGeo;
                    data.newLatLong = resGeo.cityLatLong.split(",");
                    info = JSON.stringify(data);
                }
                axios
                    .put("/v2/clearing/admin/offexchange/withdrawal/update", {
                        _id: this.withdrawalId,
                        info: info,
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            this.isShowNotif = false;
                            this.isApproved = true;
                        }
                    });
            });
        },
    },
    computed: {
        getWithdrawData() {
            return this.$store.state.bullionModule.dataShow;
        },
        getTotalData() {
            return this.$store.state.bullionModule.lengthData;
        },
        getIsLoading() {
            return this.$store.state.bullionModule.isLoadingWithdraw;
        },
        resultFilterStatus() {
            let dataLabel = [];
            for (let i = 0; i < this.dataStatus.length; i++) {
                for (let j = 0; j < this.statusValue.length; j++) {
                    if (this.dataStatus[i].code == this.statusValue[j]) {
                        dataLabel.push(this.dataStatus[i].label);
                    }
                }
            }
            return dataLabel.toString();
        },
    },
};
</script>

<style scoped>
.status-withdrawal {
    padding: 5px;
    font-weight: 600;
}

.content-filter-status {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 22rem;
}

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.filter {
    margin-bottom: 0.75rem;
    display: flex;
    gap: 0.5rem;
    flex: 0 0 100%;
}

.wrap-input {
    position: relative;
    width: 100%;
}

.form-input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #e5e4e4;
    padding: 8px 2.5rem 8px 8px;
    /* color: #e5e4e4; */
}

.input-search {
    padding: 8px 2rem 8px 8px;
}

.form-input::placeholder {
    color: #e5e4e4;
}

.form-input:focus {
    outline: none;
}

.icon-input {
    position: absolute;
    top: 0.5rem;
    right: 0.75rem;
    color: #8e8e93;
}

.filter-dropdown {
    padding: 0.75rem;
    margin: unset;
}

.filter li input[type="checkbox"] {
    margin-right: 0.5rem;
}
.menu-dropdown {
    margin: unset;
    border: 1px solid #9ca3af;
    border-radius: 5px;
}

.menu-dropdown li {
    padding: 0.75rem;
    cursor: pointer;
}

.menu-dropdown li:hover {
    background: #485a89;
    color: #fff;
}

.menu-dropdown li:not(:last-child) {
    border-bottom: inherit;
}

.disableLi {
    pointer-events: none;
    opacity: 0.6;
}

.kmi {
    color: #cbb574;
}
.kbi {
    color: #eab308;
}
.recipient {
    color: #b2902f;
}
.withdrawn {
    color: #243a72;
}
.operator {
    color: #485a89;
}
.success {
    color: #22c55e;
}
.reject {
    color: #dc2626;
}

.btn-notif {
    width: 100%;
}

.btn-close {
    box-shadow: none;
    color: #dc2626;
}

.btn-reset {
    background-color: #dc2626;
    color: #fff;
    border-radius: 0.5rem;
}
</style>
