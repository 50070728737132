<template>
    <div class="wrap-notification">
        <div class="notification">
            <div class="header">
                <img :src="require('@/assets/' + iconNotif)" alt="" />
                <div class="title-notification">{{ titleNotif }}</div>
            </div>
            <div class="body">
                <slot name="notifBody">This Is Body Notification</slot>
            </div>
            <div class="footer">
                <slot name="notifFoot">This is Footer</slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: String,
        title: String,
    },
    computed: {
        iconNotif() {
            return this.icon;
        },
        titleNotif() {
            return this.title;
        },
    },
};
</script>

<style scoped>
.wrap-notification {
    background-color: #00000073;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification {
    background: #fff;
    border-radius: 1rem;
    padding: 2rem 1.5rem;
    text-align: center;
    width: 30rem;
}

.title-notification {
    font-weight: bold;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
    font-size: 16px;
}

.footer {
    margin-top: 1.5rem;
}
</style>
